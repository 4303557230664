<template>
  <component
    :is="Footer"
    v-if="footer || footer === undefined"
    v-bind="{ ...$attrs, ...$props }"
    :variant="footer"
  />
</template>

<script lang="ts" setup>
export type TheFooterProps = { variant?: TheFooterVariant }

export type TheFooterVariant = 'condensed' | 'full'

const Footer = defineAsyncComponent(
  () => import('./TheFooterImplementation.vue'),
)

const props = defineProps<TheFooterProps>()
const { currentRoute } = useRouter()

const footer = computed(() => {
  if (props.variant !== undefined) return props.variant
  return currentRoute.value.meta.footer
})
</script>
